.App {
  height: 100vh;
  padding: 0;
  margin: 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.days {
  display: block;
  font-size: 7rem;
  font-family: "digit";
}
.left {
  display: block;
  font-size: 3rem;
  opacity: 0.8;
}

@media (prefers-color-scheme: dark) {
  .App {
    background-color: #111;
    color: #fff;
  }
}
